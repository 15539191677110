.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Home{
  /* background-image: url(/public/bg.webp); - removed - version change*/
  /* background: linear-gradient(to bottom, #6699ff 0%, #0033cc 100%); -- change on request*/
  /* background:linear-gradient(to bottom, #66ffff 0%, #0066ff 100%);
  background-size: cover; */

  background: rgb(2,0,36);
background: linear-gradient(7deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  /* height: 100%; */
  /* width: 100%; */
  /* padding: 0px 0px 50px 0px; */
}


.lm90{
  margin: 0 7%;
  justify-content: center;
}

.card-main{

  /* width: 50%;
  height: 50%; */
  padding: 25px 25px 5px 25px;
  cursor: pointer;
  color: #fff;
  margin: 10px 20px;
  /* background: rgba(255, 255, 255, 0.2); */
  background: rgba(41, 40, 40, 0.6);
  /* background: rgba(13, 13, 13, 0.861); */
  border-radius: 20px;
  /* border: 3px solid yellow !important; */
  border: 3px solid #fff !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);

}


.textAreaStyle{
  
}



.mainText{
  /* -webkit-text-fill-color: yellow; - old change */ 

  /* -webkit-text-fill-color: #e1e106; */
  -webkit-text-fill-color: #fff;
  text-shadow: 1px 1px black;
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Noto Serif Telugu', serif;
  /* text-shadow: 3px 3px red;  --  removed - version change*/

}

.mainTitleText{
  /* -webkit-text-fill-color: yellow;  */

  -webkit-text-fill-color: #e1e106;
  text-shadow: 5px 5px black;
  filter: drop-shadow(5px 3px black);
  
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Noto Serif Telugu', serif;
  text-shadow: 3px 3px red; 

}


.mainTitleText2{
  /* -webkit-text-fill-color: yellow;  */

  -webkit-text-fill-color: white;
  text-shadow: 5px 5px black;
  filter: drop-shadow(5px 3px black);
  
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Noto Serif Telugu', serif;
  text-shadow: 3px 3px black; 

}

.mainTitleText1{
  /* -webkit-text-fill-color: yellow;  */

  -webkit-text-fill-color: black;
  text-shadow: 1px 1px black;
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Noto Serif Telugu';
  text-shadow: 3px 3px red; 

}

.logoIcon{
  border-radius: 50%;
  border: 2px solid white;
  height: 70px;
  width: 70px;
  margin-right: 20px;
  margin:20px 20px 0px 10px;
  background-color: white;
  background-size: cover;
  
}

.icon{
  margin-right: 10px;
background-color: white;
border-radius: 50%;
border:1.5px solid black;
background-size:auto;

}


#navBar{
  background: rgba(41, 40, 40, 0.8) !important; 
  /* color: white; */
  /* -webkit-text-fill-color: white !important; */
}

.active{
  color:blue !important;
}



label{
  margin: 10px 0px;
}



/* Base */
/* body {
  padding: 0;
  margin: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} */
/* h1 {
  color: hsl(0, 0%, 28%);
  font-size: 50px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 7px;
  cursor: pointer;
}

h1 span {
  transition: 0.5s ease-out;
}
h1:hover span:nth-child(1) {
  margin-right: 5px;
}
h1:hover span:nth-child(1):after {
  content: "'";
}
h1:hover span:nth-child(2) {
  margin-left: 30px;
}
h1:hover span {
  color: #fff;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
} */


.floating { 
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* margin-left: 30px; */
  margin-top: 5px;
  /* -webkit-text-fill-color: yellow;
    text-shadow: 3px 3px red;  - removed - version change*/


  
  -webkit-text-fill-color: #e1e106;
  text-shadow: 1px 1px black;

  /* font-weight: 900; */
  font-size: 2rem;
  text-align: center;
  justify-content: center;
  font-family: 'Noto Serif Telugu', serif;
  /* font-family: 'Tiro Telugu', serif; */
}


@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}





.bounceInRight:hover {
    cursor: pointer;
    animation-name: bounce;
    -moz-animation-name: bounce;
  
}

@keyframes bounceInRight {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}

/* outlined-multiline-flexible:hover{
  border: none;
  border-radius: 0%;
} */

.hide{
  visibility: hidden;
}



.socialIcon:hover{
background: #16FF00 !important;
color: whitesmoke !important;
}


.footerTitle:hover{
  color: red !important;
}


.galHero{
  background-image: url(/src/images/gal.jpg);
  /* background-image: url('https://mdbcdn.b-cdn.net/img/new/slides/041.webp'); */
  height: 70vh;
  /* width: 100%; */
background-size: cover;
  background-repeat: no-repeat;
}

.gallery{
  display: grid;
  grid-template-columns:repeat(auto-fit, minmax(400px,1fr)) ;
  margin-top: 2rem;
  
}

.img-container{
  width: 100%;
  height: 100%;
  /* back: cover; */
}



.image-box {
  position: relative;
  margin: auto;
  overflow: hidden;
  /* width: 540px; */
}
.image-box img {
  max-width: 100%;
  transition: all 3s;
  display: block;
  width: 100%;
  height: auto;
  transform: scale(1);

}

.image-box:hover img {
  transform: scale(1.1);
}


.add{
  color: red !important;
  /* background-color: red; */
}

.titleFont{
  font-family: 'Press Start 2P', cursive;
  font-size: 0.7rem !important;
}

.pText{
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 0.9rem;
}

@-webkit-keyframes bounce {
  0%,  
  100% {
     -webkit-transform: translateY(0);
  } 
  50% {
      -webkit-transform: translateY(-10px);
  }
}
@-moz-keyframes bounce {
  0%, 
  100% {
     -moz-transform: translateY(0);
  }
  50% {
     -moz-transform: translateY(-10px);
  }
}
@-o-keyframes bounce {
  0%,
  100% {
     -o-transform: translateY(0);
  }
  50% {
     -o-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%,  
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}


.cardHovers{
  margin-bottom: 10px !important;
  border: 2px solid rgba(255, 255, 255, 0.879);
}
.cardHovers:hover {
  color:red !important;
  cursor: pointer;
  animation: bounce 2.5s infinite; 
  -webkit-animation: bounce 2.5s infinite; 
  -moz-animation: bounce 2.5s infinite; 
  -o-animation:bounce 2.5s infinite; 
}

/* .accordion-button{
  color:white !important;
  background-color: white !important;
} */


/* #homeBg{
  position: relative;
  top: -150px;
  z-index: -100;
  background-image: url('https://www.pixelstalk.net/wp-content/uploads/2016/10/Astrology-Desktop-Wallpaper.jpg');
  object-fit: fill;
  height: 300vh;
}*/

#homeBg{
background: rgb(2,0,36);
background: linear-gradient(7deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
min-height: 150vh;
}

.heroSec{
  background-image: url('https://www.pixelstalk.net/wp-content/uploads/2016/10/Astrology-Desktop-Wallpaper.jpg');

}

#sliderImage{
  height: 250px !important;
  object-fit: cover;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.popup-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 500px;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  background-size: contain !important;
  background-position: center !important;
  background-image: url('https://rukminim2.flixcart.com/image/850/1000/ked56kw0-0/poster/e/v/3/medium-god018-lord-shiva-parvati-wall-poster-hd-poster-for-room-original-imafv28pf5gk2ghg.jpeg?q=90') !important;
  margin: 0 auto; /* Center the container */
  position: relative;
}

.popup-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2); /* Black tint overlay */
  border-radius: 5px; /* Make sure to match the container's border-radius */
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.popup-content {
  margin-top: 40px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 5px;
}


#hovBtn:hover{
  color: black !important;
  background: white !important;
  font-size: 200px !important;
}
/* 
.popupBgImage{
  background-image:url(./images/gallery/Ganta_Plain.png);
  filter: brightness(70%);
  background-position: center center;
  background-size: cover;
  
}
 */

 /* Define styles for the overlay pseudo-element */
.popupBgImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./images/gallery/Ganta_Plain.png);
  filter: brightness(60%); /* Apply the brightness filter to the overlay */
  z-index: -1; /* Place the overlay behind other content */
  background-position: center center;
  background-size: cover;
  background-repeat: none;
}

/* Define styles for the element with the background image */
.popupBgImage {
  /* Your existing styles for the element */
  background-color: transparent; /* Make the background color transparent if needed */
  position: relative; /* Ensure the overlay is positioned relative to this element */
  background-repeat: none;

}


/* Petals.css */
@keyframes falling-petals {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(110vh);
    opacity: 1;
  }
}

.petal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1; /* Place petals behind content */
}

.petal {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: pink; /* Change this to the color you want for petals */
  border-radius: 50%;
  animation: falling-petals 5s linear infinite;
  animation-delay: calc(var(--delay) * 1s);
}

